//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    separator: {
      type: Boolean,
      default: true
    },
    return: {
      type: String,
      default: null
    },
    campaignSource: {
      type: String,
      default: null,
    },
    campaignRef: {
      type: String,
      default: null,
    },
    showLogin: {
      type: Boolean,
      default: false,
    }
  },
}
