import { render, staticRenderFns } from "./SignupModal.vue?vue&type=template&id=5e3283b4&"
import script from "./SignupModal.vue?vue&type=script&lang=js&"
export * from "./SignupModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RegistrationWrapper: require('/builds/docue/website/components/RegistrationWrapper.vue').default,IconShieldTick: require('/builds/docue/website/components/Icon/IconShieldTick.vue').default,BaseModal: require('/builds/docue/website/components/Base/BaseModal.vue').default})
