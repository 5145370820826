import { render, staticRenderFns } from "./RegistrationWrapper.vue?vue&type=template&id=a626f0da&"
import script from "./RegistrationWrapper.vue?vue&type=script&lang=js&"
export * from "./RegistrationWrapper.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OutlineXIcon: require('/builds/docue/website/node_modules/@nuxt-hero-icons/outline/src/components/XIcon.vue').default,RegistrationFormV2: require('/builds/docue/website/components/RegistrationFormV2.vue').default,Separator: require('/builds/docue/website/components/Separator.vue').default,DTButton: require('/builds/docue/website/node_modules/@docue/docue-ui/src/components/DTButton/DTButton.vue').default})
